<div class="wrapper">
  <div class="col-profile">
    <a routerLink="profil/edit" class="profile-link">
      <mp-nav-profile
        class="nav-profile"
        [user]="name$ | async"
        [company]="activeOrganisation$ | async"
        [picture]="image$ | async"
        [size]="size()"
      />
    </a>
  </div>

  <div class="col-profile-actions-button">
    <button mat-icon-button [matMenuTriggerFor]="profileActionsMenu">
      <mat-icon>manage_accounts</mat-icon>
    </button>
  </div>
</div>

<mat-menu #profileActionsMenu="matMenu">
  <button mat-menu-item (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
    <span>Abmelden</span>
  </button>

  @if (((organisationenLength$ | async) ?? 0) > 1) {
    <button mat-menu-item [routerLink]="['/active-organisation-selection']">
      <mat-icon>swap_horiz</mat-icon>
      <span>Organisation wechseln</span>
    </button>
  }
</mat-menu>
