import { Routes } from '@angular/router';

import { tabTitleResolver } from '@mp/shared/data-access';

import { ProfilEditComponent } from './edit/profil-edit.component';

export const PROFILE_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'edit',
  },
  {
    path: 'edit',
    component: ProfilEditComponent,
    title: tabTitleResolver('Profil'),
  },
];
