import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, pluck, shareReplay } from 'rxjs/operators';

import { NotificationService, filterUndefined } from '@core/shared/util';
import { Profil, ProfilActions, ProfilService, UpdateProfil, profilSelectQuery } from '@mp/shared/profil/data-access';

@Injectable()
export class ProfilFacade {
  readonly profil$: Observable<Profil> = this.store$
    .select(profilSelectQuery.PROFIL)
    .pipe(filterUndefined(), shareReplay(1));

  readonly isLoading$: Observable<boolean> = this.store$.select(profilSelectQuery.IS_LOADING);

  readonly name$: Observable<string> = this.profil$.pipe(
    map((profile) => `${profile.vorname} ${profile.nachname}`),
    shareReplay(1),
  );

  readonly image$: Observable<string | undefined> = this.profil$.pipe(
    map((profile) => (profile.bild ? `api/m/kernel/users/identity/image/${profile.bild}` : undefined)),
    shareReplay(1),
  );

  readonly activeOrganisation$: Observable<string> = this.profil$.pipe(pluck('activeOrganisation'), shareReplay(1));

  readonly organisationLength$: Observable<number> = this.profil$.pipe(
    map((profile) => profile.organisationen.length),
    shareReplay(1),
  );

  constructor(
    private readonly store$: Store,
    private readonly profilService: ProfilService,
    private readonly toaster: NotificationService,
  ) {}

  reload(): void {
    this.store$.dispatch(ProfilActions.COMPONENT.reload());
  }

  update(updateDto: UpdateProfil): void {
    this.store$.dispatch(ProfilActions.COMPONENT.update({ updateDto }));
  }

  cancelUpdate(): void {
    this.store$.dispatch(ProfilActions.COMPONENT.cancelUpdate());
  }

  changePassword(passwort: string): void {
    this.profilService.changePassword(passwort).subscribe({
      next: () => {
        this.toaster.toastSuccess('Passwort wurde erfolgreich geändert!');
      },
    });
  }
}
