import { Injectable } from '@angular/core';

import { AuthService } from '@core/shared/data-access';
import { LocalStorageService, NotificationService, ToastDuration, ToastUrgency } from '@core/shared/util';

@Injectable()
export class ProfilActionsFacade {
  constructor(
    private readonly notificationService: NotificationService,
    private readonly authService: AuthService,
    private readonly localStorageService: LocalStorageService,
  ) {}

  logout(): void {
    this.authService.logout();

    if (this.localStorageService.isAvailable()) {
      if (!this.localStorageService.read<boolean>('keepOrganisationIdAfterLogout', false)) {
        this.localStorageService.removeEntry('keepOrganisationIdAfterLogout');
        this.localStorageService.removeEntry('activeOrganisationId');
      }
    }

    this.notificationService.toastCustom('Du wirst ausgeloggt...', ToastDuration.Long, ToastUrgency.Info);
  }
}
