import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { Observable } from 'rxjs';

import { FormDirective, FormsManager, HeaderElementDirective, TypedForm, filterUndefined } from '@core/shared/util';
import {
  AvatarComponent,
  ChangePasswordInputComponent,
  CharacterLimitDirective,
  NavigateBackButtonComponent,
  RootModuleNavComponent,
  SpinnerComponent,
  ViewModule,
} from '@core/ui';
import { Profil } from '@mp/shared/profil/data-access';

import { ProfilFacade } from '../profil.facade';

type EditProfil = Pick<Profil, 'vorname' | 'nachname' | 'email'> & {
  bild: Profil['bild'] | File | '' | null;
};
type ProfilEditFormsState = { 'profil-edit': EditProfil };

@Component({
  selector: 'mp-profil-edit',
  standalone: true,
  templateUrl: './profil-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    AsyncPipe,
    DatePipe,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,

    AvatarComponent,
    ChangePasswordInputComponent,
    CharacterLimitDirective,
    FormDirective,
    HeaderElementDirective,
    NavigateBackButtonComponent,
    RootModuleNavComponent,
    SpinnerComponent,
    ViewModule,
  ],
})
export class ProfilEditComponent implements OnInit {
  readonly form: TypedForm<EditProfil>;

  readonly isLoading$: Observable<boolean> = this.facade.isLoading$;
  readonly profil$: Observable<Profil | undefined> = this.facade.profil$.pipe(filterUndefined());
  readonly name$: Observable<string> = this.facade.name$;
  readonly image$: Observable<string | undefined> = this.facade.image$;

  constructor(
    fb: UntypedFormBuilder,
    private readonly formsManager: FormsManager<ProfilEditFormsState>,
    private readonly facade: ProfilFacade,
  ) {
    this.form = this.buildForm(fb);
  }

  private buildForm(fb: UntypedFormBuilder): TypedForm<EditProfil> {
    const editForm = fb.group({
      id: [null],
      vorname: [null, Validators.required],
      nachname: [null, Validators.required],
      email: [{ value: '', disabled: true }],
      bild: [null],
    });

    return this.formsManager.createForm('profil-edit', editForm);
  }

  ngOnInit(): void {
    this.reload();
  }

  private reload(): void {
    this.facade.reload();
  }

  update(): void {
    const formValue: EditProfil = this.form.value();

    this.facade.update({
      ...formValue,
      // If image is a string (meaning it is already uploaded), then it is not changed
      bild: !formValue.bild ? '' : formValue.bild instanceof File ? formValue.bild : undefined,
    });
  }

  cancel(): void {
    this.facade.cancelUpdate();
  }

  changePassword(passwort: string): void {
    this.facade.changePassword(passwort);
  }
}
