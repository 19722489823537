import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ProfilEffects, ProfilService, profilReducer } from '@mp/shared/profil/data-access';

import { ProfilActionsFacade } from './profil-actions.facade';
import { ProfilFacade } from './profil.facade';

@NgModule({
  imports: [StoreModule.forFeature('profil', profilReducer), EffectsModule.forFeature([ProfilEffects])],
  providers: [ProfilService, ProfilFacade, ProfilActionsFacade],
})
export class ProfilModule {}
