import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map, switchMap, tap } from 'rxjs/operators';

import { LocalStorageService } from '@core/shared/util';
import { activatedRoute } from '@mp/shared/data-access';

import { ProfilService } from '../profil.service';

import { ProfilActions } from './profil.actions';

@Injectable()
export class ProfilEffects {
  loadInitial$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfilActions.COMPONENT.loadInitial),
      switchMap(() => this.service.getProfil()),
      map((loadedProfil) => ProfilActions.API.loadedInitialSuccessfully({ loadedProfil })),
    );
  });

  reload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfilActions.COMPONENT.reload),
      switchMap(() => this.service.getProfil()),
      map((loadedProfil) => ProfilActions.API.reloadedSuccessfully({ loadedProfil })),
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfilActions.COMPONENT.update),
      concatMap((action) => this.service.update(action.updateDto)),
      map((updateResult) => ProfilActions.API.updatedSuccessfully({ updateResult })),
    );
  });

  cancelUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfilActions.COMPONENT.cancelUpdate),
      map(ProfilActions.COMPONENT.canceledUpdate),
      tap(() =>
        this.router.navigate(['../../'], {
          relativeTo: activatedRoute(this.router),
        }),
      ),
    );
  });

  selectActiveOrganisation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfilActions.COMPONENT.selectActiveOrganisation),
      map((action) => {
        const organisationId = action.organisationId;
        const rememberSelectedOrganisation = action.rememberSelectedOrganisation;
        const referrerUrl = action.referrerUrl;
        const wroteSuccessfully = this.writeActiveOrganisationToLocalStorage(
          organisationId,
          rememberSelectedOrganisation,
        );

        return wroteSuccessfully
          ? ProfilActions.API.selectedActiveOrganisationSuccessfully({ organisationId, referrerUrl })
          : ProfilActions.API.selectActiveOrganisationFailed();
      }),
    );
  });

  selectedActiveOrganisationSuccessfully$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProfilActions.API.selectedActiveOrganisationSuccessfully),
        tap(({ referrerUrl }) => {
          window.location.assign(referrerUrl);
        }),
      );
    },
    { dispatch: false },
  );

  selectActiveOrganisationAutomatically$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfilActions.COMPONENT.selectActiveOrganisationAutomatically),
      map(({ organisationId }) => {
        const wroteSuccessfully = this.writeActiveOrganisationToLocalStorage(organisationId, false);

        return wroteSuccessfully
          ? ProfilActions.API.selectedActiveOrganisationAutomaticallySuccess({ organisationId })
          : ProfilActions.API.selectedActiveOrganisationAutomaticallyFail();
      }),
    );
  });

  selectedActiveOrganisationAutomaticallySuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfilActions.API.selectedActiveOrganisationAutomaticallySuccess),
      map(() => ProfilActions.COMPONENT.reload()),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly service: ProfilService,
    private readonly storage: LocalStorageService,
    private readonly router: Router,
  ) {}

  private writeActiveOrganisationToLocalStorage(organisationId: string, keepOrganisation: boolean): boolean {
    try {
      this.storage.tryWritingString('activeOrganisationId', organisationId);
      this.storage.tryWriting('keepOrganisationIdAfterLogout', keepOrganisation);

      return true;
    } catch (error: unknown) {
      return false;
    }
  }
}
